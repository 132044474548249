import { useCallback, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import JSBI from 'jsbi'
import { addConnectedWallet } from './reducer'
import { Wallet } from './types'
import { Currency, CurrencyAmount, Token } from '@uniswap/sdk-core'
import { useSingleCallResult, useSingleContractMultipleData, useMultipleContractSingleData } from 'lib/hooks/multicall'
import ERC20_ABI from '../../abis/erc20.json'
import { Interface } from '@ethersproject/abi'
import { useDefaultActiveTokens } from '../../hooks/Tokens'
import { useWeb3React } from '@web3-react/core'
import { TokenBalances } from '../../lib/hooks/useTokenList/sorting'
import { useNativeCurrencyBalances } from 'lib/hooks/useCurrencyBalance'

export function useConnectedWallets(): [Wallet[], (wallet: Wallet) => void] {
  const dispatch = useAppDispatch()
  const connectedWallets = useAppSelector((state) => state.wallets.connectedWallets)
  const addWallet = useCallback(
    (wallet: Wallet) => {
      dispatch(addConnectedWallet(wallet))
    },
    [dispatch]
  )
  return [connectedWallets, addWallet]
}

export function useTokenBalancesWithLoadingIndicator(
  address?: string,
  tokens?: (Token | undefined)[]
): [TokenBalances, boolean] {
  const validatedTokenAddresses = useMemo(() => tokens?.map(vt => vt?.address), [tokens])

  const balances = useMultipleContractSingleData(validatedTokenAddresses ?? [], new Interface(ERC20_ABI), 'balanceOf', [address])

  const anyLoading: boolean = useMemo(() => balances.some(callState => callState.loading), [balances])

  return [
    useMemo(
      () =>
        address && tokens && tokens.length > 0
          ? tokens.reduce<TokenBalances>((memo, token, i) => {
              const value = balances?.[i]?.result?.[0]
              // const amount = value ? JSBI.BigInt(value.toString()) : undefined
              const amount = value / Math.pow(10, token?.decimals ?? 18)
              if (token) {
                memo[token.address.toLowerCase()] = { usdValue: amount, balance: amount }
              }
              return memo
            }, {})
          : {},
      [address, tokens, balances]
    ),
    anyLoading
  ]
}

export function useTokenBalances(
  address?: string,
  tokens?: (Token | undefined)[]
): TokenBalances {
  return useTokenBalancesWithLoadingIndicator(address, tokens)[0]
}

export function useTokenBalance(account?: string, token?: Token): { usdValue: number; balance: number } {
  const tokenBalances = useTokenBalances(account, [token])
  if (!token) return { usdValue: 0, balance: 0 }
  return tokenBalances[token.address]
}

export function useAllTokenBalances(): TokenBalances {
  const { account, chainId } = useWeb3React()
  const allTokens = useDefaultActiveTokens(chainId)
  const allTokensArray = useMemo(() => Object.values(allTokens ?? {}), [allTokens])
  const balances = useTokenBalances(account ?? undefined, allTokensArray)
  const ethBalance = useNativeCurrencyBalances([account])
  const ethAmount = parseFloat(ethBalance[account ?? '']?.toFixed() ?? '')
  balances['ETH'] = { usdValue: ethAmount, balance: ethAmount }
  return balances ?? {}
}
