import { Percent, ChainId } from '@uniswap/sdk-core'
import JSBI from 'jsbi'

export const FACTORY_ADDRESS: { [key: number]: string } = {
  [ChainId.SCROLL_SEPOLIA]: '0x769c9ce12cD2aC2a46Bd54DF7248AFed2ED9805E',
  [ChainId.SCROLL]: '0xa63eb44c67813cad20A9aE654641ddc918412941',
}

export const INIT_CODE_HASH: { [key: number]: string } = {
  [ChainId.SCROLL_SEPOLIA]: '0x32a963eedd7683bab947b57b1d79dea7c31a054c3c80e6d74d86af0a3545fda3',
  [ChainId.SCROLL]: '0xdbe0e0e0ad073779b6496d2b29f59bc0b19eb503a3bcce6fbfdbd3b9f5dc8fe5',
}

export const MINIMUM_LIQUIDITY = JSBI.BigInt(1000)

// exports for internal consumption
export const ZERO = JSBI.BigInt(0)
export const ONE = JSBI.BigInt(1)
export const FIVE = JSBI.BigInt(5)
export const _997 = JSBI.BigInt(997)
export const _1000 = JSBI.BigInt(1000)
export const BASIS_POINTS = JSBI.BigInt(10000)

export const ZERO_PERCENT = new Percent(ZERO)
export const ONE_HUNDRED_PERCENT = new Percent(ONE)
